<script setup>
import { computed } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { Plus, Delete } from '@element-plus/icons-vue';
import { store } from '@uts/instance';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  action: {
    type: String,
    // default: `${import.meta.env.VITE_ON_LINE}/tool/oss/upload`,
    default: '/tool/oss/upload',
  },
  headers: {
    type: Object,
    default: () => ({ authorization: store.getters.token }),
  },
  size: {
    type: Number,
    default: 2,
  },
  limit: {
    type: Number,
    default: 0,
  },
  showSize: {
    type: [String, Number, Object],
    default: '',
  },
  tip: {
    type: String,
    default: '',
  },
  isOnly: {
    type: Boolean,
    default: false,
  },
  imageUrl: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue', 'update:imageUrl', 'success']);

// eslint-disable-next-line no-undef
const imageUpload = $ref(null);

const imageSize = computed(() => {
  // (props.showSize >= 40 ? props.showSize : 120)
  const type = props.showSize.constructor;
  let [width, height] = [props.showSize, props.showSize];
  if (type === Object) {
    width = `${props.showSize.width}px`;
    height = `${props.showSize.height}px`;
  } else if (type === Number) {
    width = `${props.showSize}px`;
    height = `${props.showSize}px`;
  }
  return { width, height };
});

const srcList = computed(() => props.modelValue.map((item) => loadImageUrl(item)));

function beforeImageUpload(file) {
  const { size } = props;
  const isJPG = ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type);
  const isLtSize = file.size / 1024 / 1024 < size;
  if (!isJPG) ElMessage.error('上传图片只能是 JPG/JPEG/PNG 格式!');
  if (!isLtSize) ElMessage.error(`上传图片大小不能超过 ${size}MB!`);
  return isJPG && isLtSize;
}

function uploadProgress(event, file, fileList) {
  emit('update:modelValue', fileList);
}

function onSuccess(response, file, fileList) {
  // console.log(response.message); // 这里有可能应为token无效而上传失败，可能需要处理一下
  emitValue(fileList); // 正常情况下直接调用就完事了
  if (props.isOnly) emit('update:imageUrl', response.data);
  // emit('success', { response, file });
}

function emitValue(fileList) {
  const list = fileList.map((item) => {
    const { response } = item;
    if (response) {
      if (response.code === 200) {
        return { imageUrl: response.data };
      }
      item.status = 'error';
    }
    return item;
  });
  emit('update:modelValue', list);
}

function handleRemove(index) {
  const file = props.modelValue[index];
  if (file.status === 'error') deleteImage(index);
  else {
    ElMessageBox.confirm('确认移除该图片吗？', '提示', {
      distinguishCancelAndClose: true,
      confirmButtonText: '移除',
    }).then(() => {
      if (file.percentage < 100) imageUpload.abort(file);
      deleteImage(index);
    }).catch(() => {});
  }
}

function deleteImage(index) {
  const list = [...props.modelValue];
  list.splice(index, 1);
  emit('update:modelValue', list);
  ElMessage.success('图片已移除');
}

function loadImageUrl({ raw, imageUrl }) {
  // return imageUrl || (window.URL ?? window.webkitURL).createObjectURL(raw);
  return imageUrl || URL.createObjectURL(raw);
}

</script>

<template>
  <!-- <div class="upload-image">
    <ul class="upload-image__list">
      <li
        v-for="({ raw, percentage, imageUrl, status = 'error' }, index) in modelValue"
        :key="index"
        :class="['list-item', status]"
        :style="{ width: `${imageSize}px`, height: `${imageSize}px` }"
      >
        <el-image
          class="list-item__image"
          :src="loadImageUrl({ raw, imageUrl })"
          fit="contain"
          :preview-src-list="srcList"
          hide-on-click-modal
        />
        <div class="list-item__masking" />
        <el-progress
          class="list-item__progress"
          :show-text="false"
          :percentage="percentage || 90"
          :stroke-width="4"
        />
      </li>
    </ul>
    <el-upload class="upload-image-trigger">
      <slot name="trigger">
        <el-icon class="upload-image-btn" :style="{ width: `${imageSize}px`, height: `${imageSize}px` }">
          <plus />
        </el-icon>
      </slot>
    </el-upload>
    <div class="upload-image__tip">
      <slot name="tip">先随便放几个字</slot>
    </div>
  </div> -->
  <div>
    <el-upload
      ref="imageUpload"
      class="upload-image"
      :action="action"
      :headers="headers"
      :show-file-list="false"
      multiple
      :limit="limit"
      :file-list="modelValue"
      :before-upload="beforeImageUpload"
      :on-progress="uploadProgress"
      :on-success="onSuccess"
    >
      <template #trigger>
        <div :style="{ ...imageSize }">
          <el-image v-if="isOnly && imageUrl" :src="imageUrl" />
          <slot v-else-if="!limit || modelValue.length < limit" name="trigger">
            <el-icon class="upload-btn">
              <plus />
            </el-icon>
          </slot>
        </div>
      </template>
      <!-- <slot /> -->
      <ul v-if="!isOnly" class="file-list">
        <li
          v-for="({ raw, percentage, imageUrl: url, status = 'success' }, index) in modelValue"
          :key="index"
          :style="{ ...imageSize }"
          :class="['file-item res', status]"
        >
          <el-image
            class="file-item__image"
            :src="loadImageUrl({ raw, imageUrl: url })"
            fit="contain"
            :preview-src-list="srcList"
            hide-on-click-modal
          />
          <div class="file-item__masking abs">
            <span class="fail-tip">{{ status === 'error' ? '上传失败': '' }}</span>
            <el-icon class="del-btn" :size="20" color="#F56C6C" @click="handleRemove(index)">
              <Delete />
            </el-icon>
          </div>
          <el-progress
            v-if="status === 'uploading'"
            class="progress abs"
            :show-text="false"
            :percentage="percentage"
            :stroke-width="4"
          />
          <el-icon class="remove-btn abs" :size="24" color="#FFF" @click="handleRemove(index)">
            <Delete style="padding: 3px;" />
          </el-icon>
        </li>
      </ul>
    </el-upload>
    <div class="upload-tip">
      <slot name="tip">{{ tip }}</slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// .upload-image {
//   &-btn {
//     font-size: 24px;
//     color: #8c939d;
//     border: 1px dashed #d9d9d9;
//     border-radius: 4px;
//     cursor: pointer;
//     &:hover {
//       border-color: var(--el-color-primary);
//     }
//   }
//   &__list, &-trigger {
//     display: inline-flex;
//     vertical-align: top;
//   }
//   .list-item {
//     position: relative;
//     margin: 0 5px 5px 0;
//     background: #f5f5f5;
//     border-radius: 4px;
//     overflow: hidden;

//     &.error .list-item__masking {
//       display: flex;
//       flex-direction: column;
//     }
//     &__image {
//       width: 100%;
//       height: 100%;
//     }
//     &__masking {
//       display: none;
//       position: absolute;
//       width: 100%;
//       height: 100%;
//       background: rgba(0, 0, 0, 0.4);
//       top: 0;
//       padding: 3px;
//       justify-content: center;
//       align-items: center;
//     }
//     &__progress {
//       position: absolute;
//       width: 100%;
//       padding: 0 1px;
//       bottom: 1px;
//     }
//   }
//   &__tip {
//     font-size: 12px;
//     color: var(--el-text-color-placeholder);
//   }
// }

// .el-upload__tip {
//   color: var(--el-text-color-placeholder);
// }

.upload {
  &-image {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    align-items: center;
    &:deep(.el-progress) {
      width: 100%;
    }
  }
  &-btn {
    // width: 120px;
    // height: 120px;
    font-size: 24px;
    color: #8c939d;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    &:hover {
      border-color: var(--el-color-primary);
    }
  }
  &-tip {
    margin-top: 5px;
    line-height: 18px;
    color: var(--el-text-color-placeholder);
  }
}

.file {
  &-list {
    display: flex;
  }
  &-item {
    margin-right: 5px;
    background: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;
    &:not(.error):hover .remove-btn {
      display: block;
    }
    // &.uploading,
    &.error {
      .file-item__masking {
        display: flex;
        flex-direction: column;
      }
    }
    &__image {
      width: 100%;
      height: 100%;
    }
    &__masking {
      display: none;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      top: 0;
      padding: 3px;
      justify-content: center;
      align-items: center;
      .fail-tip {
        height: 23px;
        color: #F56C6C;
      }
      .del-btn {
        cursor: pointer;
      }
    }
    .remove-btn {
      display: none;
      cursor: pointer;
      top: 0;
      right: 0;
      text-shadow: 0px 0px 3px #000;
      background: rgba(0, 0, 0, 0.4);
    }
    .progress {
      width: 100%;
      padding: 0 1px;
      bottom: 1px;
    }
  }
}
</style>
