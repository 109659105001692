import http from '@/api';

export default {
  state: {
    authData: [],
  },
  mutations: {
    SET_AUTH_TREE(state, data) {
      state.authData = data;
    },
  },
  actions: {
    getAuthData({ commit }) {
      return new Promise((resolve) => {
        http.getAuthTree({ pageSize: 9999, sysCode: 'OFFICIAL' }).then((res) => {
          commit('SET_AUTH_TREE', res?.list);
          resolve();
        });
      });
    },
  },
};
