import fetch from '@/service/fetch';

export default {
  getArticlesData: () => fetch({
    url: '/cms/admin/Statistics/newIncrement',
    method: 'get',
  }),

  getArticlesReadData: () => fetch({
    url: '/cms/admin/Statistics/readVolume',
    method: 'get',
  }),

  getArticlesReadTopData: (params) => fetch({
    url: '/cms/admin/Statistics/top10Read',
    method: 'get',
    params,
  }),

  getWebTopData: (params) => fetch({
    url: '/cms/admin/Statistics/pvuv',
    method: 'get',
    params,
  }),

  getSearchTop: (params) => fetch({
    url: '/cms/admin/Statistics/top10Keyword',
    method: 'get',
    params,
  }),
};
