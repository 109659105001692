<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { store } from '@uts/instance';

import MenuItem from './menu-item.vue';

const isCollapse = computed(() => store.getters.isCollapse);

const menus = computed(() => store.getters.menus);

const activeMenu = computed(() => {
  const { path, meta } = useRoute();
  return meta.activeMenu || path;
});

</script>

<template>
  <el-menu
    :default-active="activeMenu"
    :collapse="isCollapse"
    :unique-opened="!false"
    background-color="#314057"
    text-color="#fff"
    router
  >
    <MenuItem v-for="(item, index) in menus" :key="index" :data="item" />
  </el-menu>
</template>

<style lang="scss" scoped>
.el-menu {
  height: 100%;
  // 垂直模式样式修改
  &:not(.el-menu--horizontal):not(.el-menu--popup) {
    &:not(.el-menu--collapse) {
      width: 201px;
    }
    :deep(.el-sub-menu .el-sub-menu__title), :deep(.el-menu-item) {
      border-right: 3px solid transparent;
    }
    :deep(.el-sub-menu.is-active:not(.is-opened)) .el-sub-menu__title, :deep(.el-menu-item.is-active) {
      border-right-color: $theme-color;
      color: $theme-color !important;
    }
  }
}
</style>
