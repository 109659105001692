import Layout from '@com/layout/index.vue';
import BannerAdd from '@vws/home/banner/edit.vue';

export default {
  path: '/home',
  component: Layout,
  meta: { title: '首页管理', icon: '' },
  redirect: '/home/banner',
  children: [
    {
      path: 'banner',
      name: 'Banner',
      meta: { title: '轮播位管理' },
      component: () => import('@vws/home/banner/list.vue'),
    },
    {
      path: 'banner/add',
      name: 'BannerAdd',
      meta: { title: '新增轮播图', activeMenu: '/home/banner' },
      component: { ...BannerAdd, name: 'BannerAdd' },
    },
    {
      path: 'banner/edit/:id',
      name: 'BannerEdit',
      meta: { title: '编辑轮播图', activeMenu: '/home/banner' },
      component: () => import('@vws/home/banner/edit.vue'),
    },
  ],
};
