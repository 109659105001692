<script setup>
import { reactive, inject } from 'vue';
import md5 from 'js-md5';
import { store, router } from '@uts/instance';

const http = inject('http');

// eslint-disable-next-line no-undef
const form = $ref(null);
const state = reactive({
  form: { account: '', password: '', verify: false },
  verify: false,
  loading: false,
});

const rules = {
  account: [{ required: true, message: '请输入用户名或手机号码' }],
  password: [
    { required: true, message: '请输入密码' },
    { min: 6, max: 30, message: '密码长度为6-30位' },
  ],
  verify: [{ validator: checkVerify }],
};

function checkVerify(rule, value, callback) {
  if (!value) {
    callback(new Error('请拖动滑块完成验证！'));
  } else {
    callback();
  }
}

function onVerifyChange(value) {
  if (value) {
    form.validateField('verify');
  }
}

function handleLogin() {
  form.validate((valid) => {
    if (valid) {
      state.loading = true;
      const { account, password } = state.form;
      http.login({ account, password: md5(password) }).then((res) => {
        loadLoginInfo(res);
      }).catch(() => {
        state.loading = false;
        state.form.verify = false;
      });
    }
  });
}

function loadLoginInfo(data) {
  const {
    accessToken: { accessToken },
    userName,
    userId,
    avatar,
    userNick,
    sex,
  } = data;
  store.commit('SET_USERINFO', {
    avatar, userName, userId, userNick, sex,
  });
  store.commit('SET_TOKEN', accessToken);
  store.dispatch('initVisitedView');
  store.dispatch('getUserAuth', { userId }).then(() => {
    router.push('/');
    // const index = store.getters.menus.findIndex((item) => item.menuHref === '/dashboard');
    // if (index >= 0) {
    //   router.push('/');
    // } else {
    //   router.push(store.getters.menus[0].menuHref);
    // }
  });
}

</script>

<template>
  <div class="login">
    <div class="container flex-row">
      <div class="info">
        <div class="logo flex-row flex-top">
          <img src="@/assets/logo.png" alt="乡村振兴官网管理后台">
          <span class="app-name bold pt6 ml8">Mall Ui Admin</span>
        </div>
        <div class="adorn">
          <img src="@/assets/images/login-adorn.svg">
          <p class="title mt40">中国乡村振兴开发开网管理后台</p>
          <p class="tip mt20">登录您的账号开始使用！</p>
        </div>
      </div>
      <div class="form">
        <h2 class="mb10">登 录</h2>
        <el-form ref="form" :model="state.form" :rules="rules" size="default">
          <el-form-item prop="account">
            <el-input v-model="state.form.account" placeholder="请输入用户名/手机号" :maxlength="60" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="state.form.password"
              type="password"
              placeholder="请输入密码"
              :minlength="6"
              :maxlength="30"
            />
          </el-form-item>
          <el-form-item prop="verify">
            <SlideVerify v-model="state.form.verify" @change="onVerifyChange" />
          </el-form-item>
          <!-- <el-form-item class="mb8">
            <div class="flex-1 flex-row flex-between">
              <el-checkbox v-model="state.record">记住账号</el-checkbox>
              <el-button type="text" @click="state.verify = false">忘记密码</el-button>
            </div>
          </el-form-item> -->
          <el-form-item>
            <el-button
              style="width: 100%;"
              type="primary"
              :loading="state.loading"
              @click="handleLogin"
            >
              登 录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login {
  position: relative;
  height: 100%;
  display: flex;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-left: -48%;
    background: {
      image: url('@/assets/images/login-bg.svg');
      size: auto 100%;
      position: 100%;
      repeat: no-repeat;
    }
  }
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1520px;
  margin: 0 auto;
  z-index: 9;
}

.info, .form {
  flex: 1;
  padding-left: 56px;
}

.info {
  color: #fff;
  // padding-left: 56px;
  .logo {
    position: absolute;
    top: 50px;
    margin-left: -30px;
    font-size: 26px;
    img {
      width: 46px;
    }
  }
  .adorn {
    img {
      width: 50%;
    }
    .title {
      font-size: 30px;
      font-weight: 500;
    }
    .tip {
      font-size: 14px;
    }
  }
}

.verify {
  margin-bottom: 0;
}

:deep() {
  .el-form {
    width: 400px;
  }
  .el-slider {
    // width: calc(100% - 16px);
    // margin: 0 auto;
    .el-slider__button-wrapper {
      transform: none;
    }
  }
}
</style>
