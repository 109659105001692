import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/dist/index.css';

import store from './store';
import router from './router';

// api
import apis from './api';
// 指令
import directives from './directives';
// 样式
import './style/index.scss';

import App from './App.vue';

createApp(App)
  .provide('http', apis)
  .use(ElementPlus, { locale: zhCn, size: 'small' })
  .use(directives)
  .use(store)
  .use(router)
  .mount('#app');
