import fetch from '@/service/fetch';

export default {
  getAuthTree: (params) => fetch({
    url: '/sys/admin/menu/page',
    method: 'get',
    params,
    isForm: true,
  }),
  addAuth: (data) => fetch({
    url: '/sys/admin/menu',
    data,
    isForm: true,
  }),
  updateAuth: (data) => fetch({
    url: '/sys/admin/menu',
    method: 'put',
    data,
    isForm: true,
  }),
  deleteAuth: ({ id }) => fetch({
    url: `/sys/admin/menu/${id}`,
    method: 'delete',
  }),
};
