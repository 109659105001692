export default {
  persisted: true,
  state: {
    visitedViews: [],
  },
  mutations: {
    INIT_VISITED_VIEW(state) {
      state.visitedViews = [{ path: '/dashboard', name: 'Dashboard', title: '主控面板' }];
    },
    ADD_VISITED_VIEW(state, view) {
      const index = state.visitedViews.findIndex((v) => v.name === view.name);
      const {
        path, name, params, query, meta: { title },
      } = view;
      const route = {
        path, name, params, query, title,
      };
      if (index >= 0) {
        state.visitedViews[index] = route;
      } else {
        state.visitedViews.push(route);
      }
    },
    DEL_VISITED_VIEW(state, view) {
      const index = state.visitedViews.findIndex((v) => v.path === view.path);
      state.visitedViews.splice(index, 1);
    },
    // RESET_VISITED_VIEW(state) {
    //   state.visitedViews = [{ path: '/dashboard', name: 'Dashboard', title: '主控面板' }];
    // },
  },
  actions: {
    initVisitedView({ commit }) {
      commit('INIT_VISITED_VIEW');
    },
    addVisitedView({ commit }, view) {
      commit('ADD_VISITED_VIEW', view);
    },
    delVisitedView({ commit }, view) {
      commit('DEL_VISITED_VIEW', view);
    },
    // resetVisitedView({ commit }) {
    //   commit('RESET_VISITED_VIEW');
    // },
  },
};
