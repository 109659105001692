import { treeData } from '@uts';
import http from '@/api';

export default {
  persisted: true,
  state: {
    userInfo: {},
    token: '',
    menuAuth: [], // 用户菜单
    linkAuth: [], // 接口权限
  },
  mutations: {
    SET_USERINFO(state, data) {
      state.userInfo = data;
    },
    SET_TOKEN(state, data) {
      state.token = data;
    },
    SET_USER_AUTH(state, data) {
      const auths = treeData(data.filter(({ menuType }) => menuType === '1'));
      const links = data.filter(({ menuType }) => menuType === '2');
      state.menuAuth = auths;
      state.linkAuth = links;
    },
    RESET_USER(state) {
      Object.assign(state, {
        userInfo: {},
        token: '',
        menuAuth: [],
        linkAuth: [],
      });
    },
  },
  actions: {
    getUserAuth({ commit }, { userId }) {
      return new Promise((resolve) => {
        http.getUserAuth({ userId }).then((res) => {
          commit('SET_USER_AUTH', res);
          resolve();
        });
      });
    },
  },
};
