/* eslint-disable no-underscore-dangle */
export function formatDate(date, format = 'yyyy-MM-dd hh:mm') {
  if (!date) return '';
  let _date = date;
  if (typeof _date === 'string') {
    // 后端日期储存格式需要处理下ios兼容
    _date = date.replace(/-/g, '/');
  }
  _date = new Date(date);
  let result = format;
  const time = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    'S+': date.getMilliseconds(),
  };

  if (/(y+)/i.test(result)) {
    result = result.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
  }

  Object.keys(time).forEach((k) => {
    if (new RegExp(`(${k})`).test(result)) {
      result = result.replace(RegExp.$1, RegExp.$1.length === 1 ? time[k] : (`00${time[k]}`)
        .substr((`${time[k]}`).length));
    }
  });

  return result;
}

export function getImageUrl(name) {
  return new URL(`../assets/${name}.png`, import.meta.url).href;
}

export function treeData(data, options = {}) {
  const {
    cid, pid, parentId, sort,
  } = {
    cid: 'id',
    pid: 'parentId',
    parentId: '0',
    sort: 'treeSort',
    ...options,
  };
  const cloneData = JSON.parse(JSON.stringify(data));
  return cloneData.filter((parent) => {
    const branchArr = cloneData.filter((child) => parent[cid] === child[pid])
      .sort((a, b) => a[sort] - b[sort]);
    parent.children = branchArr.length ? branchArr : '';
    return parent[pid] === parentId;
  }).sort((a, b) => a[sort] - b[sort]);
}
