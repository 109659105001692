<script setup>
import { ref, watch } from 'vue';
import { DArrowRight, Check } from '@element-plus/icons-vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  tipText: {
    type: String,
    default: '滑向右拖动滑块',
  },
  successText: {
    type: String,
    default: '验证成功',
  },
  successFun: {
    type: Function,
    default() {},
  },
  errorFun: {
    type: Function,
    default() {},
  },
});

const emits = defineEmits(['update:modelValue', 'change']);

const disX = ref(0);

watch(() => props.modelValue, (val) => {
  if (!val) {
    disX.value = 0;
  }
  emits('change', val);
});

function verifyMove(e) {
  if (props.modelValue) {
    return false;
  }
  const ele = e.target;
  const startX = e.clientX;
  const eleWidth = ele.offsetWidth;
  const railWidth = ele.parentElement.offsetWidth;
  const MaxX = railWidth - eleWidth;
  document.onmousemove = (el) => {
    const endX = el.clientX;
    disX.value = endX - startX;
    if (disX.value <= 0) {
      disX.value = 0;
    }
    if (disX.value >= MaxX) {
      disX.value = MaxX;
    }
    ele.style.transition = '.1s all';
    el.preventDefault();
    ele.style.transition = '';
  };
  document.onmouseup = () => {
    if (disX.value !== MaxX) {
      disX.value = 0;
      ele.style.transition = '.5s all';
      // 执行成功的函数
      props.errorFun();
    } else {
      emits('update:modelValue', true);
      props.successFun();
    }
    document.onmousemove = null;
    document.onmouseup = null;
  };
  return true;
}

</script>

<template>
  <div class="slide-verify">
    <div :class="['verify-rail', { success: modelValue }]">
      <div :class="['verify-shade', { activity: !disX }]" :style="{ width: `${disX + 10}px` }" />
      <i class="verify-slide" :style="{ transform: `translateX(${disX}px)` }" @mousedown="verifyMove">
        <component :is="modelValue ? Check : DArrowRight" />
      </i>
      {{ modelValue ? successText : tipText }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@mixin jc-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide-verify {
  width: 100%;
  height: 100%;
  max-height: 50px;

  .verify-rail {
    @include jc-flex;
    height: 100%;
    background-color: #e9e9e9;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    transition: 1s all;
    user-select: none;
    color: #585858;
    &.success {
      background-color: #67c23a;
      color: #fff;
      .verify-slide {
        color: #67c23a;
      }
      .verify-shade {
        display: none;
      }
    }

    .verify-shade {
      position: absolute;
      left: 0;
      height: 100%;
      background-color: #67c23a;
      &.activity {
        transition: .5s all;
      }
    }

    .verify-slide {
      @include jc-flex;
      position: absolute;
      left: 0;
      border-radius: 4px;
      width: 50px;
      height: 100%;
      background-color: #fff;
      border: 1px solid #d8d8d8;
      cursor: pointer;
      font-size: 24px;
      svg {
        pointer-events: none;
        width: 24px;
      }
    }
  }
}
</style>
