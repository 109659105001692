import Layout from '@com/layout/index.vue';
import DocumentTem from '@vws/document/edit.vue';

export default {
  path: '/document',
  component: Layout,
  meta: { title: '文章管理', icon: '' },
  children: [
    {
      path: '',
      name: 'DocumentArticle',
      meta: { title: '文章管理' },
      component: () => import('@vws/document/list.vue'),
    },
    {
      path: 'add',
      name: 'DocumentAdd',
      meta: { title: '新增文章', activeMenu: '/document' },
      // component: () => import('@vws/document/add.vue'),
      component: { ...DocumentTem, name: 'DocumentAdd' },
    },
    {
      path: 'edit/:id',
      name: 'DocumentEdit',
      meta: { title: '编辑文章', activeMenu: '/document' },
      // component: { ...DocumentTem, name: 'DocumentEdit' },
      component: () => import('@vws/document/edit.vue'),
    },
  ],
};
