import { inject, reactive, onMounted } from 'vue';

export default function useComp(options = {}) {
  const http = inject('http');

  const { search = {}, table = {}, form = {} } = options;
  const { api, isAuto = true } = table;

  const searchConfig = reactive({
    query: {},
    currQuery: {},
    ...search,
  });

  const gridConfig = reactive({
    data: [],
    loading: false,
    paging: {},
    total: 0,
    ...table,
  });

  const formConfig = reactive({
    // title: '',
    // show: false,
    form: {},
    rules: {},
    ...form,
  });

  async function getGridData(params = {}) {
    gridConfig.loading = true;
    const data = { ...searchConfig.query, ...gridConfig.paging, ...params };
    const res = await http[api](data).catch(() => {});
    if (res) {
      const { list, total } = res;
      searchConfig.currQuery = { ...data };
      Object.assign(gridConfig, { data: list, total });
    }
    gridConfig.loading = false;
  }

  function onSearch() {
    gridConfig.paging.pageNum = 1;
    getGridData();
  }

  function onRefresh() {
    getGridData(searchConfig.currQuery);
  }

  onMounted(() => {
    if (isAuto && api) getGridData();
  });

  return {
    http,
    searchConfig,
    gridConfig,
    formConfig,
    getGridData,
    onSearch,
    onRefresh,
  };
}
