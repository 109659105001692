import http from '@/api';

export default {
  state: {
    articles: [],
    columns: [],
    isCollapse: false,
    keepAction: null, // 缓存页面是否需要做刷新或者重置首页的标识
  },
  mutations: {
    SET_ARTICLES(state, data) {
      state.articles = data;
    },
    SET_COLUMNS(state, data) {
      state.columns = data;
    },
    SET_COLLAPSE(state) {
      state.isCollapse = !state.isCollapse;
    },
    SET_KEEP_ACTION(state, data) {
      state.keepAction = data;
    },
  },
  actions: {
    getArticlesList({ commit }) {
      http.getArticleParentList({ parentId: 0 }).then((res) => {
        commit('SET_ARTICLES', res);
      });
    },
    getColumnsList({ commit }, data) {
      console.log(data);
      http.getColumnParentList({ parentId: 0 }).then((res) => {
        commit('SET_COLUMNS', res);
      });
    },
    setKeepAction({ commit }, data) {
      commit('SET_KEEP_ACTION', data);
    },
    resetKeepAction({ commit }) {
      commit('SET_KEEP_ACTION', null);
    },
  },
};
