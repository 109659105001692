import Layout from '@com/layout/index.vue';

export default {
  path: '/system',
  component: Layout,
  meta: { title: '系统设置', icon: '' },
  redirect: '/system/footer',
  children: [
    {
      path: 'article',
      name: 'ArticleType',
      meta: { title: '文章类型配置' },
      component: () => import('@vws/system/type/article.vue'),
    },
    {
      path: 'column',
      name: 'ColumnType',
      meta: { title: '菜单栏目配置' },
      component: () => import('@vws/system/type/column.vue'),
    },
    {
      path: 'role',
      // path: 'role/:roleId(\\d+)?',
      name: 'Role',
      meta: { title: '角色配置' },
      component: () => import('@vws/system/role/index.vue'),
    },
    {
      path: 'auth',
      name: 'Auth',
      meta: { title: '权限配置' },
      component: () => import('@vws/system/auth/index.vue'),
    },
    {
      path: 'user',
      name: 'User',
      meta: { title: '用户管理' },
      component: () => import('@vws/system/user/index.vue'),
    },
  ],
};
