import fetch from '@/service/fetch';

export default {
  // 面板配置
  getPanelList: (data) => fetch({
    url: '/cms/admin/pagePanel/page',
    data,
    isForm: true,
  }),
  addPanel: (data) => fetch({
    url: '/cms/admin/pagePanel',
    data,
  }),
  updatePanel: (data) => fetch({
    url: '/cms/admin/pagePanel',
    method: 'put',
    data,
  }),
  deletePanel: (data) => fetch({
    url: `/cms/admin/pagePanel/${data.id}`,
    method: 'delete',
    // data,
  }),
  getPanelData: (params) => fetch({
    url: '/cms/admin/pagePanel/panelData',
    method: 'get',
    params,
  }),
  updatePanelData: (data) => fetch({
    url: '/cms/admin/panelData/updatePanelData',
    method: 'put',
    data,
  }),
  deletePanelData: (data) => fetch({
    url: `/cms/admin/panelData/${data.id}`,
    method: 'delete',
  }),
  // 内容配置
  getContentList: (params) => fetch({
    url: '/cms/admin/dictData/listSystemCmsDictVo',
    method: 'get',
    params,
  }),
  addContent: (data) => fetch({
    // url: '/cms/admin/dictData',
    url: '/cms/admin/dictData/addSystemCmsDict',
    data,
  }),
  updateContent: (data) => fetch({
    url: '/cms/admin/dictData',
    method: 'put',
    data,
  }),
  // 页尾配置
  getFooterList: (data) => fetch({
    url: '/cms/admin/aboutUs/page',
    data,
    isForm: true,
  }),
  addFooter: (data) => fetch({
    url: '/cms/admin/aboutUs',
    data,
  }),
  updateFooter: (data) => fetch({
    url: '/cms/admin/aboutUs',
    method: 'put',
    data,
  }),
  deleteFooter: (data) => fetch({
    url: `/cms/admin/aboutUs/${data.id}`,
    method: 'delete',
    // data,
  }),
};
