import fetch from '@/service/fetch';

export default {
  login: (data) => fetch({
    url: '/sys/open/login',
    data,
    isForm: true,
  }),
  logout: () => fetch({
    url: '/sys/open/oauth/logout',
  }),
  getUserAuth: (params) => fetch({
    // url: '/sys/admin/user/getMenusByUid',
    url: '/sys/admin/user/getMenuListByUid',
    method: 'get',
    params,
  }),
  getUserList: (params) => fetch({
    url: '/sys/admin/user/page',
    method: 'get',
    params,
  }),
  deleteUser: (data) => fetch({
    url: `/sys/admin/user/${data.id}`,
    method: 'delete',
  }),
  updateUser: (data) => fetch({
    url: '/sys/admin/user',
    method: 'put',
    data,
    isForm: true,
  }),
  addUser: (data) => fetch({
    url: '/sys/admin/user',
    method: 'post',
    data,
    isForm: true,
  }),
};
