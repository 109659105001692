import fetch from '@/service/fetch';

export default {
  getBannerList: (params) => fetch({
    url: '/cms/admin/panelData/list',
    method: 'get',
    params,
  }),
  addBanner: (data) => fetch({
    url: '/cms/admin/panelData',
    data,
  }),
  updateBanner: (data) => fetch({
    url: '/cms/admin/panelData',
    method: 'put',
    data,
  }),
  deleteBanner: (data) => fetch({
    url: `/cms/admin/panelData/${data.id}`,
    method: 'delete',
  }),
  getBannerData: (params) => fetch({
    url: `/cms/admin/panelData/${params.id}`,
    method: 'get',
  }),
};
