<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
// import zhCn from 'element-plus/es/locale/lang/zh-cn';
const setTransitionName = (route) => (route.path === '/lock' ? 'lock' : 'slide');
</script>

<template>
  <!-- <el-config-provider size="small" :locale="zhCn"> -->
  <router-view v-slot="{ Component, route }">
    <transition :name="setTransitionName(route)" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <!-- </el-config-provider> -->
</template>

<style lang="scss">
html, body {
  height: 100%;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // margin-top: 60px;
  height: 100vh;
  overflow-y: auto;
}
</style>
