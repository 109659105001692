import fetch from '@/service/fetch';

export default {
  getAricleList: (params) => fetch({
    url: '/cms/admin/article/list',
    params,
  }),
  getAricle: (params) => fetch({
    url: `/cms/admin/article/${params.id}`,
    method: 'get',
  }),
  addAricle: (data) => fetch({
    url: '/cms/admin/article',
    data,
  }),
  updateAricle: (data) => fetch({
    url: '/cms/admin/article',
    method: 'put',
    data,
  }),
  deleteAricle: (data) => fetch({
    url: `/cms/admin/article/${data.id}`,
    method: 'delete',
    data,
  }),
  batchRecommend: (data) => fetch({
    url: '/cms/admin/article/batchRecommend',
    method: 'put',
    data,
    isForm: true,
  }),
  batchStatus: (data) => fetch({
    url: '/cms/admin/article/batchStatus',
    method: 'put',
    data,
    isForm: true,
  }),
  batchTop: (data) => fetch({
    url: '/cms/admin/article/batchTop',
    method: 'put',
    data,
    isForm: true,
  }),
};
