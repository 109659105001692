import { createRouter, createWebHashHistory } from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import Login from '@vws/login/index.vue';
import Layout from '@com/layout/index.vue';

import store from '@/store';

// 路由模块
const files = import.meta.globEager('./modules/*.js');
const modules = Object.keys(files).map((key) => files[key].default);

const contantRouterMap = [
  {
    path: '/login',
    name: 'Login',
    mata: { title: '登录' },
    component: Login,
  },
  {
    path: '/',
    component: Layout,
    meta: { title: '主控面板', icon: '' },
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@vws/dashboard/index.vue'),
      },
    ],
  },
  ...modules,
];

const keepFiltr = ['Login'];

const router = createRouter({
  history: createWebHashHistory(),
  routes: contantRouterMap,
});

router.beforeEach((to) => {
  NProgress.start();
  const { token } = store.getters;
  let path = true;
  if (token) {
    if (to.name === 'Login') path = { path: '/' };
  } else if (to.name !== 'Login') {
    path = { name: 'Login' };
  }
  if (!keepFiltr.includes(to.name)) {
    store.dispatch('addVisitedView', to);
  }
  NProgress.done();
  return path;
});

export default router;
