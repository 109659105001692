<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { Close } from '@element-plus/icons-vue';
import { store, router } from '@uts/instance';

const route = useRoute();

const tags = computed(() => store.state.tagsView.visitedViews);

const scrollRef = ref(null);

const left = ref(0);
const handleScroll = (e) => {
  const eventDelta = e.wheelDelta || -e.deltaY * 20;
  left.value += eventDelta;
  scrollRef.value.setScrollLeft(left.value / 4);
};

function handleTagsItem(view) {
  if (isActive(view)) return;
  router.push({ path: view.path, query: view.query });
}

function handleClose(view) {
  if (isActive(view)) {
    const index = tags.value.findIndex((v) => v.path === view.path);
    const last = tags.value[index - 1];
    const api = view.name === last.name ? 'replace' : 'push';
    router[api]({ path: last.path });
  }
  store.dispatch('delVisitedView', view);
}

function isActive(view) {
  return view.path === route.path;
}
</script>

<template>
  <div class="tags-wrap">
    <el-scrollbar
      ref="scrollRef"
      :vertical="false"
      @wheel.prevent="handleScroll"
    >
      <div class="tags-view">
        <span
          v-for="tag in tags"
          :key="tag.name"
          :class="['tags-item', { 'is-dashboard': tag.path === '/dashboard', active: isActive(tag) }]"
          @click="handleTagsItem(tag)"
        >
          {{ tag.title }}
          <el-icon
            v-if="tag.path !== '/dashboard'"
            class="tags-item_close"
            @click.stop="handleClose(tag)"
          ><Close /></el-icon>
        </span>
      </div>
    </el-scrollbar>
  </div>
</template>

<style lang="scss" scoped>
.tags-wrap {
  background: #fff;
  border-bottom: 1px solid #d8dce5;
}
.tags-view {
  padding: 2px 5px;
  height: 30px;
  white-space: nowrap;
  user-select: none;
}
.tags-item {
  display: inline-flex;
  align-items: center;
  padding: 0 12px;
  border: 1px solid #d8dce5;
  line-height: 24px;
  font-size: 12px;
  color: #495060;
  margin-right: 5px;
  cursor: pointer;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);

  &.active {
    color: #fff;
    background: $theme-color;
    border-color: $theme-color;
  }

  &:not(.is-dashboard):hover {
    padding-left: 6px;
    padding-right: 6px;
  }

  &:not(.active):hover {
    color: $theme-color;
  }

  &:hover &_close {
    width: 12px;
  }

  &_close {
    width: 0px;
    margin-left: 4px;
    transform-origin: 100% 50%;
    transition: width .3s cubic-bezier(.645, .045, .355, 1);
    &:hover {
      opacity: 0.7;
    }
  }
}
:deep(.el-scrollbar__thumb) {
  display: none;
}
</style>
