<script setup name="DocumentEdit">
import { reactive, inject, watch } from 'vue';
import { router, store } from '@uts/instance';
import { useRoute } from 'vue-router';

const http = inject('http');

const formConfig = reactive({
  form: { isRecommend: 0, isTop: 0, status: 2 },
  rules: {
    title: [{ required: true, message: '请输入文章标题', trigger: 'blur' }],
    typeIds: [{ required: true, message: '请选择文章类型', trigger: 'blur' }],
    menuIds: [{ required: true, message: '请选择菜单栏目', trigger: 'blur' }],
    content: [{ required: true, message: '请输入文章内容', trigger: 'blur' }],
    sortNum: [{ required: true, message: '请输入排序', trigger: 'blur' }],
    isRecommend: [{ required: true, message: '请选择是否推荐', trigger: 'blur' }],
    isTop: [{ required: true, message: '请选择是否置顶', trigger: 'blur' }],
    // home: [{ required: true, message: '请选择是否首页展示', trigger: 'blur' }],
    status: [{ required: true, message: '请选择是否上架', trigger: 'blur' }],
  },
  items: [
    {
      label: '文章标题',
      model: 'title',
      maxlength: 60,
      width: 400,
    },
    {
      label: '文章类型',
      model: 'typeIds',
      type: 'cascader',
      props: {
        label: 'name',
        value: 'id',
        lazy: true,
        lazyLoad(node, resolve) {
          const { data: { id } } = node;
          http.getArticleParentList({ parentId: id }).then((res) => {
            resolve(res);
          });
        },
        expandTrigger: 'hover',
        checkStrictly: true,
      },
    },
    {
      label: '菜单栏目',
      model: 'menuIds',
      type: 'cascader',
      props: {
        label: 'name',
        value: 'id',
        lazy: true,
        lazyLoad(node, resolve) {
          const { data: { id = 0 } } = node;
          http.getColumnParentList({ parentId: id }).then((res) => {
            resolve(res);
          });
        },
        expandTrigger: 'hover',
        checkStrictly: true,
      },
    },
    {
      label: '文章来源',
      model: 'source',
      maxlength: 60,
      width: 400,
    },
    { label: '封面图片', model: 'facePic', slot: 'facePic' },
    // { label: 'H5封面图片', model: 'h5FacePic', slot: 'h5FacePic' },
    {
      label: '文章内容', model: 'content', slot: 'content',

    },
    {
      label: '排序', model: 'sortNum', type: 'number', maxlength: 3, placeholder: '数字越大排展示越靠前', width: 400,
    },
    {
      label: '是否推荐',
      model: 'isRecommend',
      type: 'radio',
      options: [{ label: '否', value: 0 }, { label: '是', value: 1 }],
    },
    {
      label: '是否置顶',
      model: 'isTop',
      type: 'radio',
      options: [{ label: '否', value: 0 }, { label: '是', value: 1 }],
    },
    // {
    //   label: '是否首页展示',
    //   model: 'home',
    //   type: 'radio',
    //   options: [{ label: '否', value: 0 }, { label: '是', value: 1 }],
    // },
    {
      label: '是否上架',
      model: 'status',
      type: 'radio',
      options: [{ label: '暂不上架', value: 2 }, { label: '立即上架', value: 1 }],
    },
  ],
});

const route = useRoute();

const stop = watch(() => route.params.id, (val) => {
  if (route.name !== 'DocumentEdit') return;
  if (val && Number(val) !== formConfig.form.id) getAricleData(val);
}, { immediate: true });

if (route.name === 'DocumentAdd') stop();

async function getAricleData(id) {
  const res = await http.getAricle({ id });
  formConfig.form = { ...res, facePic: (res.facePic && [{ imageUrl: res.facePic }]) || [] };
}

function leavePage() {
  store.dispatch('delVisitedView', route);
  router.go(-1);
}

function onSubmit(model, close) {
  const { facePic, description, ...form } = formConfig.form;
  if (facePic?.length) {
    form.facePic = facePic[0].imageUrl;
  }
  form.articleTypeId = form.typeIds[form.typeIds.length - 1];
  form.menuTypeId = form.menuIds[form.menuIds.length - 1];
  http[form.id ? 'updateAricle' : 'addAricle'](form)
    .then(() => {
      store.dispatch('setKeepAction', form.id ? 'refresh' : 'reset');
      leavePage();
    })
    .catch(() => close());
}

</script>

<template>
  <div>
    <CommitForm
      v-model="formConfig.form"
      :items="formConfig.items"
      :rules="formConfig.rules"
      label-width="120px"
      @cancel="leavePage"
      @submit="onSubmit"
    >
      <template #facePic>
        <UploadImg
          v-model="formConfig.form.facePic"
          :limit="1"
          tip="最佳图片尺寸690像素*286像素"
          :show-size="{ width: 260, height: 155 }"
        >
          <template #tip>
            <p>最佳图片尺寸260像素 * 155像素</p>
            <p>只能上传1张jpg/png格式的图片，且不超过2MB</p>
          </template>
        </UploadImg>
      </template>
      <template #content>
        <Editor v-model="formConfig.form.content" height="calc(100vh - 400px)" placeholder="请输入详细的文章内容" />
      </template>
    </CommitForm>
  </div>
</template>

<style lang="scss" scoped>
:deep() {
  .el-form {
    position: relative;
    .el-form-item:nth-child(6) {
      width: calc(100% - 400px);
      position: absolute;
      top: 0;
      left: 400px;
      flex-direction: column;
      padding-left: 50px;
      padding-bottom: 20px;
      z-index: 100;
      .el-form-item__label {
        text-align: left;
        padding-bottom: 5px;
      }
      .el-form-item__content {
        align-items: flex-start;
      }
    }
  }
}
</style>
