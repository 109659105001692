import fetch from '@/service/fetch';

export default {
  // 文章类型
  getArticleParentList: (params) => fetch({
    url: '/cms/admin/articleType/list',
    method: 'get',
    params,
  }),
  getArticleType: (params) => fetch({
    url: '/cms/admin/articleType/tree',
    method: 'get',
    params,
  }),
  addArticleType: (data) => fetch({
    url: '/cms/admin/articleType',
    data,
  }),
  putArticleType: (data) => fetch({
    url: '/cms/admin/articleType',
    method: 'put',
    data,
  }),
  delArticleType: (data) => fetch({
    url: `/cms/admin/articleType/${data.id}`,
    method: 'delete',
    data,
  }),
  // 文章
  getArticle: (data) => fetch({
    url: '/cms/admin/article/page',
    data,
  }),
  addArticle: (data) => fetch({
    url: '/cms/admin/article',
    data,
  }),
  putArticle: (data) => fetch({
    url: '/cms/admin/article',
    method: 'put',
    data,
  }),
  delArticle: (data) => fetch({
    url: `/cms/admin/article/${data.id}`,
    method: 'delete',
    data,
  }),
  // 栏目
  getColumnParentList: (params) => fetch({
    url: '/cms/admin/menuType/list',
    method: 'get',
    params,
  }),
  getColumnType: (params) => fetch({
    url: '/cms/admin/menuType/tree',
    method: 'get',
    params,
  }),
  addColumnType: (data) => fetch({
    url: '/cms/admin/menuType',
    data,
  }),
  putColumnType: (data) => fetch({
    url: '/cms/admin/menuType',
    method: 'put',
    data,
  }),
  delColumnType: (data) => fetch({
    url: `/cms/admin/menuType/${data.id}`,
    method: 'delete',
    data,
  }),
};
