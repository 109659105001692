import Layout from '@com/layout/index.vue';

export default {
  path: '/content',
  meta: { title: '内容管理', icon: '' },
  component: Layout,
  children: [
    {
      path: 'panel',
      name: 'ContentPanel',
      meta: { title: '面板配置' },
      component: () => import('@vws/content/panel/list.vue'),
    },
    {
      path: 'panel/details/:id',
      name: 'ContentPanelDetails',
      meta: { title: '面板详情', activeMenu: '/content/panel' },
      component: () => import('@vws/content/panel/details.vue'),
    },
    {
      path: 'main',
      name: 'ContentMain',
      meta: { title: '内容配置' },
      component: () => import('@vws/content/main/list.vue'),
    },
    {
      path: 'footer',
      name: 'ContentFooter',
      meta: { title: '页尾配置' },
      component: () => import('@vws/content/footer/list.vue'),
    },
  ],
};
