import fetch from '@/service/fetch';

export default {
  getRoleList: (params) => fetch({
    url: '/sys/admin/role/page',
    method: 'get',
    params,
  }),
  deleteRole: (data) => fetch({
    url: `/sys/admin/role/${data.id}`,
    method: 'delete',
  }),
  updateRole: (data) => fetch({
    url: '/sys/admin/role',
    method: 'put',
    data,
  }),
  addRole: (data) => fetch({
    url: '/sys/admin/role',
    isForm: true,
    data,
  }),
  getRoleInfo: ({ id }) => fetch({
    url: `/sys/admin/role/${id}`,
    method: 'get',
  }),
  getRoleAuth: (params) => fetch({
    url: '/sys/admin/auth/getMenuIdsByRid',
    method: 'get',
    params,
  }),
  getRoleByUser: (params) => fetch({
    url: '/sys/admin/auth/getUserRoleRefListByEntity',
    method: 'get',
    params,
  }),
  updateRoleByUser: (data) => fetch({
    url: '/sys/admin/auth/updateRoleUserRefBatch',
    data,
  }),
  updateRoleByAuth: (data) => fetch({
    url: '/sys/admin/auth/updateRoleMenuRefBatch',
    data,
  }),
};
