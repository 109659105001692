<script setup>
import { computed, ref } from 'vue';

const formRef = ref(null);

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  items: {
    type: Array,
    default: () => [],
  },
  rules: {
    type: Object,
    default: () => ({}),
  },
  btnAlign: {
    type: String,
    default: '',
  },
  submitText: {
    type: String,
    default: '确定',
  },
  submitAuth: {
    type: Boolean,
    default: true,
  },
  showCancel: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue', 'cancel', 'submit']);

defineExpose({ formRef, closeLoading });

const form = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const loading = ref(false);

function handleCancel() {
  emit('cancel');
}

function handleSubmit() {
  formRef.value.validate((valid) => {
    if (valid) {
      loading.value = true;
      emit('submit', form.value, closeLoading);
    }
  });
}

function closeLoading() {
  loading.value = false;
}

function disabled(item) {
  // const { disabled } = item;
  // const isDisabled = typeof disabled === 'function' ? disabled(form) : disabled;
  const isFun = typeof item.disabled === 'function';
  return isFun ? item.disabled(form) : item.disabled;
}

</script>

<template>
  <el-form
    v-bind="$attrs"
    ref="formRef"
    :model="form"
    :rules="rules"
  >
    <el-form-item
      v-for="(item, index) in items"
      :key="index"
      :label="item.label"
      :prop="item.model"
      :required="item.required"
      :rules="item.rules"
      :style="{ width: `${item.width}px` }"
    >
      <slot v-if="item.slot" :name="item.slot" />
      <el-select
        v-else-if="item.type === 'select'"
        v-model="form[item.model]"
        :placeholder="item.placeholder || `请选择${item.label}`"
        :disabled="disabled(item)"
        clearable
        @change="item.selectChangeFun"
      >
        <el-option
          v-for="opt in item.options"
          :key="opt[item.prop?.value||'value']"
          :label="opt[item.prop?.label||'label']"
          :value="opt[item.prop?.value||'value']"
        />
      </el-select>
      <el-cascader
        v-else-if="item.type === 'cascader'"
        v-model="form[item.model]"
        :options="item.options"
        :props="item.props"
      />
      <el-time-picker
        v-else-if="item.type === 'time'"
        v-model="form[item.model]"
        :placeholder="item.placeholder || `请选择${item.label}`"
        clearable
        :editable="false"
        :format="item.format"
        :disabled="typeof item.disabled === 'function' ? item.disabled(form) : item.disabled"
        @change="item.onChange"
      />
      <el-date-picker
        v-else-if="item.type === 'date'"
        v-model="form[item.model]"
        :placeholder="item.placeholder || `请选择${item.label}`"
        :type="item.dateType"
        clearable
        :editable="false"
        :format="item.format"
        :default-value="item.defaultValue"
        :disabled="typeof item.disabled === 'function' ? item.disabled(form) : item.disabled"
        @change="item.onChange"
      />
      <el-radio-group
        v-else-if="item.type === 'radio'"
        v-model="form[item.model]"
        :disabled="typeof item.disabled === 'function' ? item.disabled(form) : item.disabled"
        @change="item.onChange"
      >
        <el-radio v-for="opt in item.options" :key="opt.value" :label="opt.value">{{ opt.label }}</el-radio>
      </el-radio-group>
      <el-input
        v-else-if="item.type === 'number'"
        v-model.number="form[item.model]"
        oninput="value = value.replace(/[^\d]/g, '')"
        :placeholder="item.placeholder || `请输入${item.label}`"
        :maxlength="item.maxlength"
        :disabled="typeof item.disabled === 'function' ? item.disabled(form) : item.disabled"
        clearable
      />
      <el-input
        v-else
        v-model="form[item.model]"
        :type="item.type"
        :maxlength="item.maxlength"
        :placeholder="item.placeholder || `请输入${item.label}`"
        :disabled="typeof item.disabled === 'function' ? item.disabled(form) : item.disabled"
        :readonly="typeof item.readonly === 'function' ? item.readonly(form) :item.readonly"
        clearable
      />
    </el-form-item>
    <el-form-item :class="btnAlign">
      <el-button v-if="showCancel" @click="handleCancel">取消</el-button>
      <el-button
        type="primary"
        :disabled="loading || !submitAuth"
        :loading="loading"
        @click="handleSubmit"
      >
        {{ submitText }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<style lang="scss" scoped>
.center :deep(.el-form-item__content) {
  margin-left: 0 !important;
  justify-content: center;
}
.right :deep(.el-form-item__content) {
  justify-content: flex-end;
}
</style>
