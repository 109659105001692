<script setup name="BannerEdit">
import { watch } from 'vue';
import useComp from '@uts/useComp';
import { ElMessage } from 'element-plus';
import { router, store } from '@uts/instance';
import { useRoute } from 'vue-router';

const form = {
  form: {
    panelId: 1, status: 2, url: '', isOutsideUrl: '',
  },
  items: [
    {
      label: '轮播图标题', model: 'title', width: 400, maxlength: 60,
    },
    { label: '轮播图', model: 'picUrl', slot: 'picUrl' },
    { label: '跳转链接', model: 'url', width: 400 },
    {
      label: '内/外部链接',
      model: 'isOutsideUrl',
      type: 'select',
      options: [{ label: '外部', value: 0 }, { label: '内部', value: 1 }],
    },
    {
      label: '排序值', model: 'sortNum', type: 'number', placeholder: '数字越大越靠前', width: 400, maxlength: 3,
    },
    {
      label: '是否上架',
      model: 'status',
      type: 'radio',
      options: [{ label: '暂不上架', value: 2 }, { label: '立即上架', value: 1 }],
    },
  ],
  rules: {
    title: [{ required: true, message: '请输入轮播图标题', trigger: 'blur' }],
    picUrl: [{ required: true, message: '请上传轮播图', trigger: 'blur' }],
    sortNum: [{ required: true, message: '请输入排序值', trigger: 'blur' }],
    status: [{ required: true, message: '请选择是否上架', trigger: 'blur' }],
    isOutsideUrl: [{
      validator(rule, value, callback) {
        if (formConfig.form.url && value === '') {
          callback(new Error('请选择内/外部链接'));
        } else {
          callback();
        }
      },
      trigger: 'blur',
    }],
  },
};

const { http, formConfig } = useComp({ form });

const route = useRoute();

const stop = watch(() => route.params.id, (val) => {
  if (route.name !== 'BannerEdit') return;
  if (val && Number(val) !== formConfig.form.id) getBannerData(val);
}, { immediate: true });

if (route.name === 'BannerAdd') stop();

watch(() => formConfig.form.url, (val) => { formConfig.items[3].required = !!val; });

async function getBannerData(id) {
  const res = await http.getBannerData({ id });
  formConfig.form = { ...res, picUrl: [{ imageUrl: res.picUrl }] };
}

function leavePage() {
  store.dispatch('delVisitedView', route);
  router.go(-1);
}

function onSumbit(model, close) {
  const { picUrl, ...data } = formConfig.form;
  data.picUrl = picUrl[0].imageUrl;
  http[data.id ? 'updateBanner' : 'addBanner'](data)
    .then(() => {
      ElMessage.success('操作成功');
      leavePage();
      // 如果新增跟编辑要做缓存的话需要在保存后移除缓存
    })
    .catch(() => close());
}

</script>

<template>
  <div>
    <CommitForm
      v-model="formConfig.form"
      :items="formConfig.items"
      :rules="formConfig.rules"
      :is-colon="false"
      :label-width="90"
      @cancel="leavePage"
      @submit="onSumbit"
    >
      <template #picUrl>
        <UploadImg v-model="formConfig.form.picUrl" :limit="1">
          <template #tip>
            <p>最佳图片尺寸730像素*395像素</p>
            <p>只能上传1张jpg/png格式的图片，且不超过2MB</p>
          </template>
        </UploadImg>
      </template>
    </CommitForm>
  </div>
</template>

<style lang="scss" scoped>

</style>
