<script setup>
import { inject, computed } from 'vue';
import { store, router } from '@uts/instance';
import { Expand, Fold } from '@element-plus/icons-vue';

import FullScreen from '@com/btns/full-screen.vue';
import Menu from './menu.vue';
import Tags from './tags.vue';

const http = inject('http');

const userInfo = computed(() => store.state.user.userInfo);

const cachedViews = computed(() => {
  const list = store.state.tagsView.visitedViews.map((v) => v.name);
  return list;
});

const isCollapse = computed(() => store.getters.isCollapse);

const circleUrl = 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png';

function handleCollapse() {
  store.commit('SET_COLLAPSE');
}

function handleLogout() {
  http.logout().then(() => {
    store.commit('RESET_USER');
    router.push('/login');
  });
}
</script>

<template>
  <el-container class="layout-container">
    <el-header class="layout-header">
      <div class="header-left">
        <span class="title">乡村振兴官网管理后台</span>
        <el-icon class="pointer" :size="20" color="#666" @click="handleCollapse">
          <Expand v-if="isCollapse" />
          <Fold v-else />
        </el-icon>
      </div>
      <div class="header-right">
        <FullScreen />
        <el-dropdown>
          <span class="userInfo pointer">
            <el-avatar :src="userInfo.avatar || circleUrl" :size="32" />
            <span class="ml10">{{ userInfo.userNick }}</span>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="$router.push('/')">主控面板</el-dropdown-item>
              <!-- <el-dropdown-item>个人中心</el-dropdown-item> -->
              <el-dropdown-item divided @click="handleLogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-header>
    <el-container style="overflow: hidden;">
      <el-aside width="auto">
        <Menu />
      </el-aside>
      <el-container class="layout-content">
        <!-- <div class="layout-tabs-view"></div> -->
        <Tags />
        <el-main class="layout-main ma10 pa20">
          <router-view v-slot="{ Component }">
            <transition name="el-fade-in-linear" mode="out-in">
              <keep-alive :include="cachedViews">
                <component :is="Component" />
              </keep-alive>
            </transition>
          </router-view>
        </el-main>
        <!-- <el-footer>底部</el-footer> -->
      </el-container>
    </el-container>
  </el-container>
</template>

<style lang="scss" scoped>
.layout-container {
  height: 100%;
}
.layout-header {
  z-index: 1;
  border-bottom: solid 1px #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    margin-right: 20px;
    line-height: 20px;
  }
  .header-left, .header-right {
    display: flex;
    align-items: center;
  }
  .userInfo {
    display: flex;
    align-items: center;
  }
}
.layout-content {
  background: #f3f8fd;
  display: flex;
  flex-direction: column;
}
.layout-main {
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
  min-height: 0;
  flex: 1;
  overflow-y: auto;
}
</style>
