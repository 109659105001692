<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import ScreenFull from 'screenfull';
import { FullScreen, Crop } from '@element-plus/icons-vue';

const isFullscreen = ref(false);

onMounted(() => {
  window.addEventListener('keydown', keydown, true);
  ScreenFull.onchange(() => {
    isFullscreen.value = ScreenFull.isFullscreen;
  });
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', keydown);
  ScreenFull.off('change');
});

function keydown(event) {
  if (event.keyCode === 122) {
    event.returnValue = false;
    changeScreen();
  }
}

function changeScreen() {
  if (ScreenFull.isEnabled) {
    ScreenFull.toggle();
  }
}
</script>

<template>
  <el-tooltip effect="light" :content="isFullscreen?'正常':'全屏'" placement="bottom">
    <div class="header-menu-btn" @click="changeScreen">
      <el-icon :size="22" color="#666">
        <Crop v-if="isFullscreen" />
        <FullScreen v-else />
      </el-icon>
    </div>
  </el-tooltip>
</template>

<style lang="scss" scoped>

</style>
