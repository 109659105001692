import { store } from '@uts/instance';

const directives = {
  auth(el, binding) {
    const links = store.getters.links.map(({ permission }) => permission);
    const { value } = binding;
    if (value && !links?.includes(value)) {
      el.parentNode?.removeChild(el);
    }
  },
};

export default function install(vue) {
  Object.keys(directives).forEach((dir) => {
    vue.directive(dir, directives[dir]);
  });
}
